const data = [
  {
    title: "What is Depeg Nation?",
    text: "Depeg Nation is the first NFT commemorative community-driven project that launches on Terra 2.0. NFT community was always strong on the Terra chain and this is our way of preserving it. The main focus will be to increase adoption of Web 3.0 with the use of creator DAPPS.",
  },
  {
    title: "On which chain Depeg Nation is?",
    text: "The project born on Terra 2.0 and we aim to stay there and revamping the NFT community that was a key point on the old chain.",
  },
  {
    title: "What is MFER collection?",
    text: "This is our first collection, minted On June 16th, 1 month, 1 week, 1 day, 1 hour and 1 minute after The Great Depeg, the first mint on Terra 2.0 that also minted out very quickly, holders will be able to generate revenue by staking those NFTs.",
  },
  {
    title: "What is Tales of dUST?",
    text: "It is our second collection, it contains WEAPONs and CHESTs, Weapons holders will be able to generate yeld from the games that will be built, on the other side Chests are our gamified IDO for our dUST Token, the main currency to play our games.",
  },
  {
    title: "What is DAO Nation?",
    text: "It's the Decentralized Autonomous Organization that will be governed only by MFER holders. DAO members have the responsability to vote on a common fee for all games created by The Depeg Nation, vote on Treasury Investments/ Distribution, vote on the new games that will launch for users, take part in marketing movements.",
  },
  {
    title: "What is the Master's Death?",
    text: "After 9 months since The Great Depeg everyone’s expecting the Master’s Death. He will be happy to turn into his own creation dUST in order to let everyone have a brighter fully decentralized future. You will be able to burn your MFER and gain rewards based on a leaderboard. The higher the stake streak of your MFER the higher the reward.",
  },
];

export default data;
