import { FiCheck, FiMinus } from "react-icons/fi";
import thumb1 from "../../images/nft/roadmap_item1.gif";
import thumb2 from "../../images/nft/roadmap_item2.gif";
import thumb3 from "../../images/nft/roadmap_item3.gif";
import thumb4 from "../../images/nft/roadmap_item4.jpg"; 

const data = [
  {
    title: "Phase 01",
    thumb: thumb1,
    features: [
      {
        icon: <FiCheck />,
        text: "Creation of Depeg Nation"
    },
    {
        icon: <FiCheck />,
        text: "First Ever Mint on Terra 2.0"
    },
    {
        icon: <FiCheck />,
        text: "V1 DAO Nation is formed"
    },
    {
        icon: <FiCheck />,
        text: "Community collaborations"
    },
    {
        icon: <FiCheck />,
        text: "First NFT Generator on Terra 2.0"
    },
    ],
  },

  {
    title: "Phase 02",
    thumb: thumb2,
    features: [
      {
        icon: <FiCheck />,
        text: "Creating the first Discord Web 3.0 game"
      },
      {
          icon: <FiCheck />,
          text: "Reveal of the second collection"
      },
      {
          icon: <FiCheck />,
          text: "Lauching the first game on testnet"
      },
      {
          icon: <FiCheck />,
          text: "Marketing on social networks"
      },
      {
        icon: <FiCheck />,
        text: "CoinFlip dUST Game Launch "
      },
      {
          icon: <FiMinus />,
          text: "Minting Tales of dUST - Weapons and Chests"
      },
    ],
  },

  {
    title: "Phase 03",
    thumb: thumb3,
    features: [
      {
          icon: <FiMinus />,
          text: "Astroport LP Bootstrap"
      },
      {
          icon: <FiMinus />,
          text: "Gamified CHEST opening event"
      },
      {
        icon: <FiMinus />,
        text: "dUST Games mainnet deploy"
      },
      {
          icon: <FiMinus />,
          text: "MFER & WEAPON staking"
      }, 
      {
          icon: <FiMinus />,
          text: "Rumble dUST Game Launch "
      }, 
    ],
  },

  {
    title: "Phase 04",
    thumb: thumb4,
    features: [
      {
        icon: <FiMinus />,
        text: "Cosmos Exploration"
      }, 
      {
          icon: <FiMinus />,
          text: "The FREN Attack"
      }, 
      {
          icon: <FiMinus />,
          text: "More games added"
      }, 
      {
          icon: <FiMinus />,
          text: "The FREN Attack mint"
      },

      {
        icon: <FiMinus />,
        text: "DAO v2 is formed"
      },
      {
          icon: <FiMinus />,
          text: "Dashboard on website"
      },
      {
          icon: <FiMinus />,
          text: "Spread dUST Games on every discord"
      },
      {
          icon: <FiMinus />,
          text: "The Master Death"
      },
      {
          icon: <FiMinus />,
          text: "Burn of MFER to gain rewards"
      },
    ],
  },
];

export default data;
