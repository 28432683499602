import nftImg1 from "../images/nft/portfolio2/weapon1.jpg";
import nftImg2 from "../images/nft/portfolio2/weapon2.jpg";
import nftImg3 from "../images/nft/portfolio2/weapon3.jpg";
import nftImg4 from "../images/nft/portfolio2/weapon4.jpg";
import nftImg5 from "../images/nft/portfolio2/weapon5.jpg";
import nftImg6 from "../images/nft/portfolio2/gif2.gif";
import nftImg7 from "../images/nft/portfolio2/weapon7.jpg";
import nftImg8 from "../images/nft/portfolio2/weapon8.jpg";
import nftImg9 from "../images/nft/portfolio2/weapon9.jpg";
import nftImg10 from "../images/nft/portfolio2/weapon10.jpg";
import nftImg11 from "../images/nft/portfolio2/weapon11.jpg";
import nftImg12 from "../images/nft/portfolio2/gif5.gif";
import nftImg13 from "../images/nft/portfolio2/weapon6.jpg";


const data = [
  nftImg1,
  nftImg2,
  nftImg3,
  nftImg4,
  nftImg5,
  nftImg6,
  nftImg7,
  nftImg8,
  nftImg9,
  nftImg10,
  nftImg11,
  nftImg12,
  nftImg13,
];

export default data;
