import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/Header";
import Banner from "../components/section/banner";
import TextSlider from "../components/section/textSlider";
import About from "../components/section/about/";
import Features from "../components/section/features/";
import Portfolio from "../components/section/portfolio";
import RoadMap from "../components/section/roadMap";
import Team from "../components/section/team/";
import FAQ from "../components/section/faq/";
import Footer from "../components/section/footer/";
const Home = () => {

  return (
    <>
      <Layout>
        <GlobalStyles />
        <Header />
        <Banner />
        <TextSlider />
        <About />
        <Features />
        <Portfolio />
        <RoadMap />
        <Team />
        <FAQ />
        <Footer />
      </Layout>
    </>
  );
};

export default Home;
