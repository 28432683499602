import { FaDiscord, FaTwitter } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import logo from "../../../../assets/images/logo.png";

import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {

  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#roadmap">Roadmap</a>
            </li>
            <li>
              <a href="#team">Team</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
            
            <li>
              <a href="https://whitepaper.depeg.io" onClick={(e) => {e.preventDefault();window.open("https://whitepaper.depeg.io","_blank")}}>WhitePaper</a>
            </li>
            <li>
              <a href="https://stake.depeg.io">Staking</a>
            </li>
            
          </ul>
        </div>
        <div className="mobile_menu_social_links">

          <a href="https://twitter.com/DepegN" onClick={(e) => {e.preventDefault();window.open("https://twitter.com/DepegN","_blank")}}>
            <FaTwitter />
          </a>
          <a href="https://discordapp.com/users/142396982244278272" onClick={(e) => {e.preventDefault();window.open("https://discordapp.com/users/142396982244278272","_blank")}}>
            <FaDiscord />
          </a>
        </div>

      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
