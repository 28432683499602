import aboutCardIcon1 from "../images/icon/sword_icon.png";
import aboutCardIcon2 from "../images/icon/dust_coin.png";
import aboutCardIcon5 from "../images/icon/aboutus_card_icon5.png";

const data = [
  {
    icon: aboutCardIcon5,
    title: "MFER Staking",
    text: "A pool of 9,300,000 dUST tokens will be put up for grabs for 18 months for our MFERs holders.",
  },
  {
    icon: aboutCardIcon1,
    title: "WEAPON Staking",
    text: "Weapons are able to be staked on each individual game. 100% of Rewards go to Weapons stakers.",
  },
  {
    icon: aboutCardIcon2,
    title: "Play to Earn",
    text: 'The utility of the dUST token focuses on the usage of the token into different dAPPS using Discord',
  },
];

export default data;
