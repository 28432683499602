import { useEffect, useState } from "react";
import { FaDiscord } from "react-icons/fa";
import { MdNotes } from "react-icons/md";
import Button from "../../../common/button";
import NavWrapper from "./Header.style";
import MobileMenu from "./mobileMenu/MobileMenu";
import logo from "../../../assets/images/logo_text.png";

const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };
  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  return (
    <>
      <NavWrapper className="bithu_header" id="navbar">
        <div className="container">
          <div className="bithu_menu_sect">
            <div className="bithu_menu_left_sect">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="bithu nft logo" />
                </a>
              </div>
            </div>
            <div className="bithu_menu_right_sect bithu_v1_menu_right_sect">
              <div className="bithu_menu_list">
                <ul>
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#roadmap">Roadmap</a>
                  </li>
                  <li>
                    <a href="#team">Team</a>
                  </li>
                  <li>
                    <a href="#faq">FAQ</a>
                  </li>
                  <li>
                    <a href="https://whitepaper.depeg.io" onClick={(e) => {e.preventDefault();window.open("https://whitepaper.depeg.io","_blank")}}>WhitePaper</a>
                  </li>
                  <li>
                    <a href="https://stake.depeg.io">Staking</a>
                  </li>
                  
                </ul>
              </div>
              <div className="bithu_menu_btns">
                <button className="menu_btn" onClick={() => handleMobileMenu()}>
                  <MdNotes />
                </button>
                <Button sm variant="outline" className="join_btn" onClick={(e) => {e.preventDefault();window.open("https://discord.gg/ugpQ9uD3YG","_blank")}}>
                  <FaDiscord /> Join
                </Button>

              </div>
            </div>
          </div>
        </div>
      </NavWrapper>
      {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
    </>
  );
};

export default Header;
