import { FaDiscord } from "react-icons/fa";

import avatarThumb1 from "../../images/team/crew_avatar1.png";
import avatarThumb2 from "../../images/team/crew_avatar2.png";
import avatarThumb3 from "../../images/team/crew_avatar3.png";
import avatarThumb4 from "../../images/team/crew_avatar4.png";

const data = [
  {
    avatar: avatarThumb1,
    name: "Vicorico",
    designation: "Founder",
    socialLinks: [
      {
        icon: <FaDiscord />,
        url: "https://discordapp.com/users/313053965648658432",
      },
    ],
  },
  {
    avatar: avatarThumb2,
    name: "Octane",
    designation: "Co-Founder",
    socialLinks: [
      {
        icon: <FaDiscord />,
        url: "https://discordapp.com/users/142396982244278272",
      },
    ],
  },
  {
    avatar: avatarThumb3,
    name: "iceicedavid",
    designation: "Artist",
    socialLinks: [
      {
        icon: <FaDiscord />,
        url: "https://discordapp.com/users/360088461945995271",
      },
    ],
  },
  {
    avatar: avatarThumb4,
    name: "AnonME",
    designation: "Collaborations",
    socialLinks: [
      {
        icon: <FaDiscord />,
        url: "https://discordapp.com/users/520631867259748352",
      },
    ],
  },
];

export default data;
