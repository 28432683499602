import shapeIcon from "../images/icon/mint_live_icon.svg";

const data = [
  {
    icon: shapeIcon,
    text: "Mint NOW",
  },
  {
    icon: shapeIcon,
    text: "Tales of dUST",
  },
  {
    icon: shapeIcon,
    text: "Mint NOW",
  },
  {
    icon: shapeIcon,
    text: "Tales of dUST",
  },
  {
    icon: shapeIcon,
    text: "Mint NOW",
  },
  {
    icon: shapeIcon,
    text: "Tales of dUST",
  },
  {
    icon: shapeIcon,
    text: "Mint NOW",
  },
  {
    icon: shapeIcon,
    text: "Tales of dUST",
  },
  {
    icon: shapeIcon,
    text: "Mint NOW",
  },
  {
    icon: shapeIcon,
    text: "Tales of dUST",
  },
  {
    icon: shapeIcon,
    text: "Mint NOW",
  },
  {
    icon: shapeIcon,
    text: "Tales of dUST",
  },
];

export default data;
