import { Slider, SliderItem } from "../../../common/slider/Slider";
import Button from "../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import catFootIcon from "../../../assets/images/icon/dust-text-small.svg";
import catFootIconBg from "../../../assets/images/icon/cat_walk_round_shap.svg";
import imgFrameShape from "../../../assets/images/nft/nft-img-shape.png";

import weaponThumb1 from "../../../assets/images/nft/weapon1.jpg";
import weaponThumb2 from "../../../assets/images/nft/weapon2.jpg";
import weaponThumb3 from "../../../assets/images/nft/weapon3.jpg";
import weaponThumb4 from "../../../assets/images/nft/weapon4.jpg";
import weaponThumb5 from "../../../assets/images/nft/weapon5.jpg";
import weaponThumb6 from "../../../assets/images/nft/weapon6.jpg";
import weaponThumb7 from "../../../assets/images/nft/weapon7.jpg";
import weaponThumb8 from "../../../assets/images/nft/weapon8.jpg";
import weaponThumb9 from "../../../assets/images/nft/weapon9.jpg";
import weaponThumb10 from "../../../assets/images/nft/weapon10.jpg";
import weaponThumb11 from "../../../assets/images/nft/weapon11.jpg";
import weaponThumb12 from "../../../assets/images/nft/weapon12.jpg";
import weaponThumb13 from "../../../assets/images/nft/weapon13.jpg";
import weaponThumb14 from "../../../assets/images/nft/weapon14.jpg";
import weaponThumb15 from "../../../assets/images/nft/weapon15.jpg";
import weaponThumb16 from "../../../assets/images/nft/weapon16.jpg";
import weaponThumb17 from "../../../assets/images/nft/weapon17.jpg";
import chestsGif from "../../../assets/images/nft/chests290.gif";
import overlayImg from "../../../assets/images/nft/banner_mesh.png"
import star1 from "../../../assets/images/icon/star1.svg"
import star2 from "../../../assets/images/icon/star2.svg"
import star3 from "../../../assets/images/icon/star3.svg"

import Particle from "./Particles"

const Banner = () => {
  const slideImages = [ weaponThumb1, weaponThumb2, weaponThumb3, weaponThumb4, weaponThumb5, weaponThumb6, weaponThumb7, weaponThumb8, weaponThumb9, weaponThumb10, weaponThumb11, weaponThumb12, weaponThumb13, weaponThumb14, weaponThumb15, weaponThumb16, weaponThumb17,  ]
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    speed: 500,
    fade: true,
    autoplaySpeed: 500,
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };



  return (
    <BannerV1Wrapper id="home">
      <Particle />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-0">
            <div className="bithu_v1_baner_left">
              <div className="banner_title">
                <h2 className="circle_cat_foot">Tales
                  <span>
                    <img src={catFootIcon} alt="icon" />
                    <img src={catFootIconBg} className="foot_circle" alt="icon" />
                  </span>
                </h2>
                <h2>of dUST</h2>
              </div>

              <div className="coin_info">
                <h4>Mint now!</h4>
                <h4>launchpad: Knowhere.art</h4>
              </div>

              <div className="banner_buttons">
                <Button lg variant="outline"  onClick={(e) => {e.preventDefault();window.open("https://knowhere.art/launchpad/collection/terra1kad44vp08yqcz9ec366krj89yvsvs6nmauvhqu6sl93jjw5ugw2qd3xdnw","_blank")}}>
                  {" "}
                  Mint now
                </Button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-0 order-lg-1">
            <div className="bithu_v4_baner_right">
              <div className="banner_card banner_card_angle">
                <img src={chestsGif} className="banner_img" alt="thumb" />
                <div className="banner_card_shapes">
                  <img src={imgFrameShape} alt="shape" />
                </div>
              </div>
              <div className="banner_card">
                <Slider {...settings}>
                  {slideImages?.map((thumb, idx) => (
                    <SliderItem key={idx}>
                      <img src={thumb} alt="thumb" />
                    </SliderItem>
                  ))}
                </Slider>
                <div className="banner_card_shapes">
                  <img src={imgFrameShape} alt="shape" />
                </div>
              </div>

              {/* star particles */}
              <div className="star_particles">
                <span className="star_1">
                  <img src={star1} alt="icon" />
                </span>
                <span className="star_2">
                  <img src={star2} alt="icon" />
                </span>
                <span className="star_3">
                  <img src={star3} alt="icon" />
                </span>
              </div>
              {/* bg overlay */}
              <span className="bg_overlay">
                <img src={overlayImg} alt="thumb" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
