import aboutThumb1 from "../../images/nft/themaster260.jpg";
import aboutThumb2 from "../../images/nft/infinitygauntlet260.gif";
import aboutThumb3 from "../../images/nft/fren260.jpg";
import aboutIcon from "../../images/icon/ab-icon-image.png";
import aboutIconBw from "../../images/icon/ab-icon-image-bw.png";

const data = {
  aboutDescription1: 'Thousands of bulls and bears are getting butchered by the death spiral.',
  aboutDescription2: 'Some heard rumors about it before, but no one could believe that such great power would get summoned and make it all true. They were all too faded by the moon s eclipse.',
  aboutDescription3: 'The Master opened his third eye. He was created by the bloodbath, falling tears, and love for a great nation. Its mission was that if a decentralized economy were to be created it must not lose the significance of not only new discoveries but respect, loyalty, and appreciation for anything that helped the vision of the world evolve.',
  aboutDescription4: 'Making use of one of the most popular cryptocurrency communication channels Discord, its capability of adding permissionless bots, and the interoperability of the Cosmos ecosystem, The Master believed that dUST will become more powerful than anyone could ever imagine by exploring a new use case for the blockchain technology. ',
  collections: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "MFERs",
      role: "First collection on Terra 2.0",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Tales of dUST",
      role: "Weapons and token distribution",
    },
    {
      thumb: aboutThumb3,
      icon: aboutIconBw,
      name: "FRENs",
      role: "Coming soon",
    },
  ],
};

export default data;
