import { useState } from "react";
import { ModalContext } from "./ModalContext";

const ContextProvider = ({ children }) => {
  const [visibility] = useState(false);


  return (
    <ModalContext.Provider
      value={{visibility}}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ContextProvider;
