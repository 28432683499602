import nftImg1 from "../images/nft/portfolio/gif1.gif";
import nftImg2 from "../images/nft/portfolio/weapon1.jpg";
import nftImg3 from "../images/nft/portfolio/weapon2.jpg";
import nftImg4 from "../images/nft/portfolio/weapon3.jpg";
import nftImg5 from "../images/nft/portfolio/weapon4.jpg";
import nftImg6 from "../images/nft/portfolio/gif3.gif";
import nftImg7 from "../images/nft/portfolio/weapon5.jpg";
import nftImg8 from "../images/nft/portfolio/weapon6.jpg";
import nftImg9 from "../images/nft/portfolio/weapon7.jpg";
import nftImg10 from "../images/nft/portfolio/weapon8.jpg";
import nftImg11 from "../images/nft/portfolio/weapon9.jpg";
import nftImg12 from "../images/nft/portfolio/gif4.gif";
import nftImg13 from "../images/nft/portfolio/weapon10.jpg";
import nftImg14 from "../images/nft/portfolio/weapon11.jpg";
import nftImg15 from "../images/nft/portfolio/weapon12.jpg";
import nftImg16 from "../images/nft/portfolio/weapon13.jpg";


const data = [
  nftImg1,
  nftImg2,
  nftImg3,
  nftImg4,
  nftImg5,
  nftImg6,
  nftImg7,
  nftImg8,
  nftImg9,
  nftImg10,
  nftImg11,
  nftImg12,
  nftImg13,
  nftImg14,
  nftImg15,
  nftImg16,
];

export default data;
